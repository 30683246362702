
      import Vue from 'vue'
      import axios from 'axios'

      Vue.config.silent = true

      const hostname = window.location.hostname
      const domain = hostname.slice(hostname.indexOf('.') + 1)
      const env =
        (domain === 'qmai.cn' && 'master') ||
        (domain === 'qmai.com' && 'master') ||
        (domain === 'qimai.shop' && 'test') ||
        (domain === 'qmai.co' && 'beta') ||
        (domain === 'zmcms.cn' && 'dev') ||
        (domain === 'sgp.qimai.shop' && 'test')

      if (env !== 'master' || window.location.href.includes('enableVConsole=1')) {
        const VConsole = require('vconsole')
        new VConsole()
      }

      const storeId = window.storeId || window.localStorage.getItem('storeId') || ''

      if (!storeId) {
        throw new Error('店铺ID为空，请检查URL中是否存在store_id，或者缓存中是否存在storeId')
      }

      const STORE_PREFIX = storeId + '_'

      const storeName = window.storeName || window.localStorage.getItem(STORE_PREFIX + 'storeName') || ''
      const weChatOfficialAccountAppid = window.weChatOfficialAccountAppid || window.localStorage.getItem(STORE_PREFIX + 'weChatOfficialAccountAppid') || ''

      axios({
        method: 'get',
        url: 'https://webapi.' + domain + '/web/iot-center/ext-info/detail?sellerId=' + storeId,
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          window.extConfig = Object.assign(response.data.data, {
            appName: storeName,
            storeId: storeId,
            appid: weChatOfficialAccountAppid,
          })
          import("./app.mpx?isApp").then(({ default: App }) => {
            new Vue({
              el: '#app',
              render: function(h){
                return h(App)
              }
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
      

      